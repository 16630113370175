import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// custom components
import Header from "../components/Header";
import Footer from "../components/Footer";
import Seo from "../components/Seo";
import CallAction from "../components/CallAction";

const useStyles = makeStyles((theme) => ({
  main: {
    overflowX: "hidden",
    margin: '0 15%',
    padding: '50px 0',
    textAlign: 'center'
  },
  img: {
    width: "80%",
    margin: '15px auto',
  },
  link: {
    textDecoration: "none",
  }
}));

export default function Page404(){
  const classes = useStyles();

  // data from graphql
  const data = useStaticQuery(graphql`
    query {
      image_404: file(relativePath: { eq: "404.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={classes.root}>
      <Seo title="Không tim thấy trang 404" />

      {/** Header */}
      <Header />

      {/** Main */}
      <main className={classes.main}>
        <Link to="/" className={classes.link}>
          <Button variant="contained" color="secondary">Back To Home</Button>
        </Link>
        
        <Img className={classes.img} fluid={data.image_404.childImageSharp.fluid} />
      </main>

      {/** Call action */}
      <CallAction />

      {/** Footer */}
      <Footer />
    </div>
  );
}